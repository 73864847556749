<template>
  <div>
    <b-alert
      :variant="alertData.variant"
      :show="dismissCountDown"
      dismissible
      fade
      class="mb-1"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span><strong>{{ alertData.strongText }}</strong> {{ alertData.lightText }}</span>
      </div>
    </b-alert>
    <b-overlay :show="saving">
      <validation-observer
        ref="form"
        #default="{invalid}"
      >
        <form @submit.prevent="onSubmit">
          <b-card
            footer-class="text-muted"
            body-class="p-0"
          >
            <b-tabs
              v-model="tabIndex"
              pills
              nav-class="mb-3 pt-2 pr-2 pl-2"
            >
              <b-tab
                button-id="form"
              >
                <div slot="title">
                  <feather-icon
                    class="mr-1"
                    icon="AlertCircleIcon"
                  /> {{ $t('calcParamUpsertForm.form.title') }}
                </div>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.nomem')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.nomem')"
                        vid="nomem"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.nomem"
                          name="nomem"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.nomemPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.foten')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.foten')"
                        vid="foten"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.foten"
                          name="foten"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.fotenPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.eleen')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.eleen')"
                        vid="eleen"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.eleen"
                          name="eleen"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.eleenPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.cpmax')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.cpmax')"
                        vid="cpmax"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.cpmax"
                          name="cpmax"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.cpmaxPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.cpres')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.cpres')"
                        vid="cpres"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.cpres"
                          name="cpres"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.cpresPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.fgcab')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.fgcab')"
                        vid="fgcab"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.fgcab"
                          name="fgcab"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.fgcabPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.ftcam')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.ftcam')"
                        vid="ftcam"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.ftcam"
                          name="ftcam"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.ftcamPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.trpac')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.trpac')"
                        vid="trpac"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.trpac"
                          name="trpac"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.trpacPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.betal')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.betal')"
                        vid="betal"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.betal"
                          name="betal"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.betalPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.qnneu')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.qnneu')"
                        vid="qnneu"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.qnneu"
                          name="qnneu"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.qnneuPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="12"
                    md="12"
                    lg="12"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.kneut')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.kneut')"
                        vid="kneut"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.kneut"
                          name="kneut"
                          step="any"
                          type="number"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.kneutPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.ntpft')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.ntpft')"
                        vid="ntpft"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.ntpft"
                          name="ntpft"
                          step="any"
                          type="number"
                          readonly
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.ntpftPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.causa')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.causa')"
                        vid="causa"
                        rules="required"
                      >
                        <b-form-input
                          :value="fixCausa(form.causa)"
                          readonly
                          name="causa"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('calcParamUpsertForm.form.causaPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.AssetId')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.AssetId')"
                        vid="AssetId"
                        rules="required"
                      >
                        <asset-select
                          v-if="assets.length"
                          v-model="form.AssetId"
                          name="AssetId"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :assets="assets"
                          :placeholder="$t('calcParamUpsertForm.form.AssetIdPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('calcParamUpsertForm.form.ReducingDeciLayerId')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('calcParamUpsertForm.form.ReducingDeciLayerId')"
                        vid="ReducingDeciLayerId"
                        rules="required"
                      >
                        <reducing-deci-layer-select
                          v-if="reducingDeciLayers.length"
                          v-model="form.ReducingDeciLayerId"
                          name="ReducingDeciLayerId"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :reducing-deci-layers="reducingDeciLayers"
                          :placeholder="$t('calcParamUpsertForm.form.ReducingDeciLayerIdPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>

            <div
              slot="footer"
              style="display: flex; justify-content: space-between;"
            >
              <div>
                <b-button
                  id="save-form"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid || saving"
                >
                  <b-spinner
                    v-if="saving"
                    small
                  />
                  {{ saving ? $t('calcParamUpsertForm.saving') : $t('calcParamUpsertForm.save') }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  :disabled="saving"
                  @click.prevent="goToList"
                >
                  {{ $t('calcParamUpsertForm.cancel') }}
                </b-button>
              </div>
            </div>
          </b-card>
        </form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BTabs, BTab, BCard, BFormGroup, BFormInput, BRow,
  BCol, BButton, BSpinner, BOverlay, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssetSelect from '@/views/components/AssetSelect.vue'
import ReducingDeciLayerSelect from '@/views/components/ReducingDeciLayerSelect.vue'

export default {
  name: 'CalcParamUpsertForm',

  components: {
    BTabs,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BAlert,
    AssetSelect,
    ReducingDeciLayerSelect,
  },
  directives: { Ripple },

  data() {
    return {
      assets: [],
      reducingDeciLayers: [],
      // Alert
      dismissSecs: 4,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertData: {
        lightText: null,
        strongText: null,
        variant: 'danger',
      },

      tabIndex: 0,
      saving: false,
      form: {
        nomem: '',
        foten: '',
        eleen: '',
        cpmax: null,
        cpres: null,
        fgcab: null,
        ftcam: null,
        trpac: null,
        betal: null,
        qnneu: null,
        kneut: null,
        AssetId: null,
        ReducingDeciLayerId: null,
        ntpft: 0.00041,
        causa: 'aceleratorHasRoundFieldOnBorders',
      },
      required,
    }
  },
  computed: { fixCausa() { return causa => this.$t(`calcParams.${causa}`) } },
  async created() { await this.load() },
  methods: {
    ...mapActions('calcParam', {
      loadCalcParam: 'getupsert',
      upsertCalcParam: 'upsert',
    }),
    goToList() { this.$router.push({ name: 'pages-calc-param' }) },
    async load() {
      this.reducingDeciLayers = await this.$store.dispatch('reducingDeciLayer/index')
      this.assets = await this.$store.dispatch('asset/index')
      await Promise.all([this.loadCalcParamForRoute()])
    },
    showAlert(alertProps, variant) {
      this.alertData = alertProps
      this.alertData.variant = variant
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async loadCalcParamForRoute() {
      const { id } = this.$route.params
      if (id === 'new') return
      const calcParam = await this.loadCalcParam(id)
      const keysToDelete = ['deletedAt', 'createdAt', 'updatedAt']
      keysToDelete.forEach(key => { delete calcParam[key] })
      this.form = calcParam
    },
    async onSubmit() {
      const success = await this.$refs.form.validate()
      if (!success) { return }
      const calcParam = { ...this.form }

      this.saving = true
      try {
        const updated = await this.upsertCalcParam(calcParam)
        this.showAlert(this.$t('calcParamUpsertForm.methods.onSubmit.alert'), 'success')
        if (updated.id !== Number(this.$route.params.id)) {
          this.$router.push({ name: 'pages-calcparam-upsert', params: { id: updated.id } })
          await this.loadCalcParamForRoute()
          return
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            ...this.$t('calcParamUpsertForm.upsertdErrorToast'),
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } finally { this.saving = false }
    },
  },
}
</script>

<style>
  @media (max-width: 767px){
    .nav-pills{
      display: block;
    }
    .small-devices-display{
      display: block;
    }
  }
  .flex-description-row {
    display: flex;
    align-items: center;
    align-content: center;
  }
</style>
