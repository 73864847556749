var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{staticClass:"mb-1",attrs:{"variant":_vm.alertData.variant,"show":_vm.dismissCountDown,"dismissible":"","fade":""},on:{"dismiss-count-down":_vm.countDownChanged}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.alertData.strongText))]),_vm._v(" "+_vm._s(_vm.alertData.lightText))])])]),_c('b-overlay',{attrs:{"show":_vm.saving}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-card',{attrs:{"footer-class":"text-muted","body-class":"p-0"}},[_c('b-tabs',{attrs:{"pills":"","nav-class":"mb-3 pt-2 pr-2 pl-2"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"button-id":"form"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"AlertCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('calcParamUpsertForm.form.title'))+" ")],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.nomem')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.nomem'),"vid":"nomem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"nomem","placeholder":_vm.$t('calcParamUpsertForm.form.nomemPlaceholder')},model:{value:(_vm.form.nomem),callback:function ($$v) {_vm.$set(_vm.form, "nomem", $$v)},expression:"form.nomem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.foten')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.foten'),"vid":"foten","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"foten","placeholder":_vm.$t('calcParamUpsertForm.form.fotenPlaceholder')},model:{value:(_vm.form.foten),callback:function ($$v) {_vm.$set(_vm.form, "foten", $$v)},expression:"form.foten"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.eleen')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.eleen'),"vid":"eleen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"eleen","placeholder":_vm.$t('calcParamUpsertForm.form.eleenPlaceholder')},model:{value:(_vm.form.eleen),callback:function ($$v) {_vm.$set(_vm.form, "eleen", $$v)},expression:"form.eleen"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.cpmax')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.cpmax'),"vid":"cpmax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"cpmax","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.cpmaxPlaceholder')},model:{value:(_vm.form.cpmax),callback:function ($$v) {_vm.$set(_vm.form, "cpmax", $$v)},expression:"form.cpmax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.cpres')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.cpres'),"vid":"cpres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"cpres","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.cpresPlaceholder')},model:{value:(_vm.form.cpres),callback:function ($$v) {_vm.$set(_vm.form, "cpres", $$v)},expression:"form.cpres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.fgcab')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.fgcab'),"vid":"fgcab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"fgcab","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.fgcabPlaceholder')},model:{value:(_vm.form.fgcab),callback:function ($$v) {_vm.$set(_vm.form, "fgcab", $$v)},expression:"form.fgcab"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.ftcam')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.ftcam'),"vid":"ftcam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"ftcam","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.ftcamPlaceholder')},model:{value:(_vm.form.ftcam),callback:function ($$v) {_vm.$set(_vm.form, "ftcam", $$v)},expression:"form.ftcam"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.trpac')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.trpac'),"vid":"trpac","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"trpac","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.trpacPlaceholder')},model:{value:(_vm.form.trpac),callback:function ($$v) {_vm.$set(_vm.form, "trpac", $$v)},expression:"form.trpac"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.betal')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.betal'),"vid":"betal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"betal","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.betalPlaceholder')},model:{value:(_vm.form.betal),callback:function ($$v) {_vm.$set(_vm.form, "betal", $$v)},expression:"form.betal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.qnneu')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.qnneu'),"vid":"qnneu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"qnneu","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.qnneuPlaceholder')},model:{value:(_vm.form.qnneu),callback:function ($$v) {_vm.$set(_vm.form, "qnneu", $$v)},expression:"form.qnneu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.kneut')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.kneut'),"vid":"kneut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"kneut","step":"any","type":"number","placeholder":_vm.$t('calcParamUpsertForm.form.kneutPlaceholder')},model:{value:(_vm.form.kneut),callback:function ($$v) {_vm.$set(_vm.form, "kneut", $$v)},expression:"form.kneut"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.ntpft')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.ntpft'),"vid":"ntpft","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"ntpft","step":"any","type":"number","readonly":"","placeholder":_vm.$t('calcParamUpsertForm.form.ntpftPlaceholder')},model:{value:(_vm.form.ntpft),callback:function ($$v) {_vm.$set(_vm.form, "ntpft", $$v)},expression:"form.ntpft"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.causa')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.causa'),"vid":"causa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"value":_vm.fixCausa(_vm.form.causa),"readonly":"","name":"causa","placeholder":_vm.$t('calcParamUpsertForm.form.causaPlaceholder')}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.AssetId')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.AssetId'),"vid":"AssetId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.assets.length)?_c('asset-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"AssetId","assets":_vm.assets,"placeholder":_vm.$t('calcParamUpsertForm.form.AssetIdPlaceholder')},model:{value:(_vm.form.AssetId),callback:function ($$v) {_vm.$set(_vm.form, "AssetId", $$v)},expression:"form.AssetId"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('calcParamUpsertForm.form.ReducingDeciLayerId')}},[_c('validation-provider',{attrs:{"name":_vm.$t('calcParamUpsertForm.form.ReducingDeciLayerId'),"vid":"ReducingDeciLayerId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.reducingDeciLayers.length)?_c('reducing-deci-layer-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"name":"ReducingDeciLayerId","reducing-deci-layers":_vm.reducingDeciLayers,"placeholder":_vm.$t('calcParamUpsertForm.form.ReducingDeciLayerIdPlaceholder')},model:{value:(_vm.form.ReducingDeciLayerId),callback:function ($$v) {_vm.$set(_vm.form, "ReducingDeciLayerId", $$v)},expression:"form.ReducingDeciLayerId"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"slot":"footer"},slot:"footer"},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"id":"save-form","type":"submit","variant":"primary","disabled":invalid || _vm.saving}},[(_vm.saving)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.saving ? _vm.$t('calcParamUpsertForm.saving') : _vm.$t('calcParamUpsertForm.save'))+" ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","type":"reset","disabled":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.goToList($event)}}},[_vm._v(" "+_vm._s(_vm.$t('calcParamUpsertForm.cancel'))+" ")])],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }