<template>
  <div>
    <v-select
      ref="select"
      v-bind="$attrs"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="options"
      :selectable="option => !option.group"
      :filter="filter"
      :reduce="$attrs.reduce || (item => item.id)"
      v-on="$listeners"
    >
      <span
        slot="no-options"
        @click="$refs.select.open = false"
      >
        {{ placeholder }}
      </span>
      <template #option="{ label }">
        <div class="">
          <span class="font-weight-boldest">{{ label }}</span>
        </div>
        <div class="clearfix" />
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },

  props: {
    assetId: { type: [Number, null], default: null },
    placeholder: { type: String, default: null },
    assets: { type: Array, default() { return [] } },
  },

  computed: {
    options() {
      return this.assets.map(p => ({ id: p.id, label: p.name }))
    },
    selectedOptions() {
      return this.options.find(o => o.id === this.assetId)
    },
  },
  methods: {
    filter(options, searh) {
      return options.filter(o => o.label.toLowerCase().includes(searh.toLowerCase()))
    },
  },
}
</script>
